import { Link } from 'react-router-dom';

import { Mixpanel } from 'mixpanel';

import { signOut } from 'reduxStore/auth/auth.slice';
import { useDispatch, useSelector } from 'reduxStore/hooks';

import Button from 'components/button/button';

import collablogo from 'images/collab-logo.png';

import style from './appHeader.module.scss';

export default function AppHeader() {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const isSignedIn = Boolean(user);

  return (
    <div className={style.appHeader}>
      <div className={style.logo} data-testid="logo">
        <Link to="/">
          <img src={collablogo} alt="Collab.Land Dev Portal" />
        </Link>
      </div>
      <ul className={style.navigation} data-testid="navigation">
        <li>
          <a
            href="https://docs.collab.land/docs/intro"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              Mixpanel.track('dev-portal header-btn', {
                button: 'Docs',
              });
            }}
          >
            Docs
          </a>
        </li>
        {/* <li>
          <a
            href="https://www.collab.land/support"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              Mixpanel.track('dev-portal header-btn', {
                button: 'Support',
              });
            }}
          >
            Support
          </a>
        </li> */}
        {/* <Link to="/pricing">Pricing</Link> */}
        {isSignedIn ? (
          <li>
            <Button
              outline
              color="danger"
              className={style.logoutButton}
              onClick={() => dispatch(signOut())}
            >
              Log out
            </Button>
          </li>
        ) : null}
      </ul>
    </div>
  );
}
